import Image from 'next/future/image';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { PageType } from '@/lib/buyback/types';
import arrowRightIcon from '@/public/icons/buyback/sell-device/arrow-right.svg';

const ProgressBar = ({
  pageType,
  back,
  step,
  subStep = 0,
  steps,
  category,
}: {
  pageType: PageType;
  back: () => void;
  step: number;
  subStep?: number;
  steps: number;
  category?: string;
}) => {
  const router = useRouter();
  const percentage = ((step + subStep) * 100) / (steps - 1);

  const onBackClick = () => {
    switch (pageType) {
      case PageType.BRAND:
        router.push('/buyback/sell/electronic');
        break;
      case PageType.MODEL:
        router.push(`/buyback/sell/${category}`);
        break;
      default:
        back();
    }
  };

  return (
    <div>
      {pageType === PageType.NAVIGATION ? (
        <h5 className="text-center">Get your free quote in 2 minutes</h5>
      ) : (
        <div className="flex items-center justify-between">
          <button
            type="button"
            onClick={() => onBackClick()}
            className="flex h-6 w-6 items-center justify-center"
          >
            <Image
              alt="Back"
              src={arrowRightIcon}
              height={50}
              width={50}
              quality={100}
              className="h-full w-auto rotate-180 object-contain"
            />
          </button>
          <h5 className="text-center">Get Your Free Quote</h5>
          <div />
        </div>
      )}
      <div className="mt-2 h-3 w-full rounded-full bg-gray-200">
        <div
          className={cn(
            'h-3 bg-green transition-all duration-150 ease-in-out',
            {
              'rounded-full': percentage === 100,
              'rounded-l-full': percentage < 100,
            },
          )}
          // eslint-disable-next-line react/forbid-dom-props
          style={{
            width: `calc(${percentage}%)`,
          }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
