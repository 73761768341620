import { Dispatch, SetStateAction, useState } from 'react';
import Image from 'next/future/image';
import { Tab } from '@headlessui/react';
import cn from 'classnames';
import { BuybackProductType } from '@lambda/apis/src/dynamoDb/types';
import Modal from '../Modal';
import { getEdgeUrl } from '@/lib/getEdgeUrl';
import { DeviceConditions } from '@/lib/buyback/types';

const CONDITIONS = {
  [DeviceConditions.FLAWLESS]: 'Flawless',

  [DeviceConditions.MINOR]: 'Minor',

  [DeviceConditions.MODERATE]: 'Moderate',

  [DeviceConditions.CRACKED]: 'Cracked',
};

export default function ConditionModal({
  showModal,
  setShowModal,
  type = 'screen',
  category = 'phone',
  brand = 'apple',
}: Readonly<{
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  type: 'screen' | 'housing';
  category: BuybackProductType;
  brand: string;
}>) {
  const [condition, setCondition] = useState('flawless');

  return (
    <Modal
      showModal={showModal}
      setShowModal={setShowModal}
      title="Condition Detail"
    >
      <div className="flex-1 overflow-auto p-4">
        <div className="rounded-lg bg-[#EFF2F9] px-8 pt-8">
          <div className="flex h-[300px] shrink-0 items-center justify-center">
            <Image
              alt="Condition"
              src={getEdgeUrl(
                `images/buyback/questionnaire/modal/condition/${type}/${condition}-${brand.toLocaleLowerCase()}-${category.toLocaleLowerCase()}.png`,
              )}
              height={500}
              width={500}
              quality={100}
              className="h-full w-auto object-contain"
            />
          </div>
        </div>
        <div className="mt-4">
          <Tab.Group>
            <Tab.List className="flex space-x-2 rounded-xl p-1">
              {Object.keys(CONDITIONS).map((c) => (
                <Tab
                  onClick={(e: any) =>
                    setCondition(
                      e.target.innerText.toLowerCase().split(' ').join('-'),
                    )
                  }
                  key={c}
                  className={({ selected }) =>
                    cn(
                      'w-full rounded-md border p-3 text-xs transition duration-100 ease-in-out hover:border-gray-700 hover:font-bold',
                      { 'border-gray-700 font-bold': selected },
                    )
                  }
                >
                  {c}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="mt-2">
              {Object.values(CONDITIONS).map((post, idx) => (
                <Tab.Panel key={idx} className="rounded-xl bg-white p-3">
                  {post}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </Modal>
  );
}
