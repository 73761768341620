import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Image from 'next/future/image';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { BuybackProductType } from '@lambda/apis/src/dynamoDb/types';
import {
  DeviceT,
  DeviceVariantT,
  PageType,
  SelectedVariantsT,
  VariantsT,
} from '@/lib/buyback/types';
import { BuybackUSConfig } from '@/settings/configs/buyback/us/type';
import {
  generateSelectVariantTitle,
  generateIsGrid,
  mapVariantToIcon,
  streamlineCondition,
} from '@/lib/buyback/helpers';
import arrowRightIcon from '@/public/icons/buyback/sell-device/arrow-right.svg';
import checkIcon from '@/public/icons/buyback/check.svg';
import ProgressBar from '../ProgressBar';
import { COMMON_VARIANTS, CONDITIONS } from '@/lib/buyback/constants';
import howToIcon from '@/public/icons/buyback/sell-device/how-to.svg';
import ConditionModal from './ConditionModal';
import { useBuybackContext } from '@/lib/buyback/BuybackContext';
import {
  BuybackSalesChannel,
  deduceConditionFromDeviceIssues,
} from '../../common';
import settings from '@/settings';
import sparksIcon from '@/public/icons/buyback/conditions/flawless.svg';
import { SegmentEvent } from '@/lib/analytics/events';
import { trackQuoteEvent } from '@/lib/analytics/trackQuote';

const Card = ({
  variant,
  option,
  nextStep,
  selectedVariants,
  category,
}: {
  variant: DeviceVariantT;
  option: string;
  nextStep: (key: DeviceVariantT, value: string) => void;
  selectedVariants: SelectedVariantsT;
  category: BuybackProductType;
}) => {
  const { updateFinalQuote } = useBuybackContext();

  const icon = mapVariantToIcon(variant.variant, option, category);
  const isGrid = generateIsGrid(variant.variant);

  return (
    <button
      type="button"
      onClick={() => {
        updateFinalQuote({ [variant.variant]: option });
        nextStep(variant, option);
      }}
      className={cn(
        'group flex cursor-pointer items-center justify-between overflow-hidden rounded-md border-2 p-4 transition duration-150 ease-in-out hover:bg-gray-300',
        {
          'border-gray-700': selectedVariants[variant.variant] === option,
          'flex-col gap-4': isGrid,
        },
      )}
    >
      <div className="flex items-center gap-4">
        {icon && !isGrid && (
          <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-[#ECFCF5] group-hover:bg-gray-300">
            <div className="flex h-5 w-5 shrink-0 items-center justify-center">
              <Image
                alt="Next"
                src={icon}
                height={50}
                width={50}
                quality={100}
                className="h-full w-auto object-contain grayscale group-hover:grayscale-0"
              />
            </div>
          </div>
        )}
        <span>{option}</span>
      </div>
      {!isGrid ? (
        <div className="flex h-8 w-8 shrink-0 items-center justify-center">
          <Image
            alt="Next"
            src={arrowRightIcon}
            height={50}
            width={50}
            quality={100}
            className="h-full w-auto object-contain"
          />
        </div>
      ) : (
        <div
          className={cn(
            'relative flex h-24 w-36 shrink-0 items-center justify-center md:h-32 md:w-48',
            {
              'mb-[-18px] md:mb-[-23px]': category !== 'laptop',
            },
          )}
        >
          <Image
            alt={`${variant.variant} ${option}`}
            src={icon}
            height={50}
            width={50}
            quality={100}
            className="h-full w-full object-contain"
          />
          {option === 'Flawless' && (
            <div
              className={cn('absolute h-8 w-8 md:h-10 md:w-10', {
                'top-[-30px] right-0 md:right-[10px]': category !== 'laptop',
                'top-[-25px] right-[-20px] md:top-[-30px] md:right-[-20px]':
                  category === 'laptop',
              })}
            >
              <Image
                alt="Flawless"
                src={sparksIcon}
                height={50}
                width={50}
                quality={100}
                className="h-full w-full object-contain"
              />
            </div>
          )}
        </div>
      )}
    </button>
  );
};

const Variant = ({
  variant,
  device,
  nextStep,
  selectedVariants,
}: {
  variant: DeviceVariantT;
  device: BuybackUSConfig;
  nextStep: (key: DeviceVariantT, value: string) => void;
  selectedVariants: SelectedVariantsT;
}) => {
  const [showHelpMeModal, setShowHelpMeModal] = useState(false);
  const viewTitle = generateSelectVariantTitle(
    variant.variant,
    device?.title ?? '',
    device?.category,
  );

  const isGrid = generateIsGrid(variant.variant);

  const showHelpButton =
    settings.store === 'reebelo-dev' &&
    (variant.variant === 'screen' || variant.variant === 'housing');

  const generateOptions = () => {
    const { options } = variant;

    const sizeToGB = (size: string) => {
      const num = parseFloat(size);

      if (size.endsWith('TB')) return num * 1024;
      if (size.endsWith('GB')) return num;

      return NaN;
    };

    // We don't want to sort Screen Condition (Front), Housing Condition (Back) and Condition (Yes/No)
    if (!['screen', 'housing', 'condition'].includes(variant.variant)) {
      return options.sort((a, b) => {
        const sizeA = sizeToGB(a);
        const sizeB = sizeToGB(b);

        if (Number.isNaN(sizeA) && Number.isNaN(sizeB))
          return a.localeCompare(b); // Both are non-numeric or non-standard units, sort alphabetically
        if (Number.isNaN(sizeA)) return 1; // `a` is non-numeric or non-standard, `b` is numeric or standard, `a` should come after `b`
        if (Number.isNaN(sizeB)) return -1; // `a` is numeric or standard, `b` is non-numeric or non-standard, `a` should come before `b`

        return sizeA - sizeB; // Both are numeric or standard, sort numerically
      });
    }

    return options;
  };

  const options = generateOptions();

  return (
    <>
      <div className="flex flex-wrap items-center justify-between gap-x-4 ">
        <h4 className="text-lg font-bold">{viewTitle}</h4>
        {showHelpButton && (
          <button
            type="button"
            className="flex items-center gap-2 font-bold text-[#497B7D] underline"
            onClick={() => setShowHelpMeModal(true)}
          >
            <div className="mt-[-1px] flex h-4 w-4 shrink-0 items-center justify-center">
              <Image
                alt="How to find this"
                src={howToIcon}
                height={50}
                width={50}
                quality={100}
                className="h-full w-auto object-contain"
              />
            </div>
            Help me choose
          </button>
        )}
      </div>

      {/* Is it in good working condition? */}
      {variant.variant === 'condition' && (
        <div className="mt-4 flex flex-col gap-1">
          {CONDITIONS.map((condition) => (
            <div className="flex items-center gap-3" key={condition.label}>
              <div className="flex h-4 w-4 shrink-0 items-center justify-center">
                <Image
                  alt="Check"
                  src={checkIcon}
                  height={50}
                  width={50}
                  quality={100}
                  className="h-full w-auto object-contain"
                />
              </div>
              <div>{condition.label}</div>
            </div>
          ))}
        </div>
      )}

      {/* Variants */}
      <div
        className={cn('mt-4', {
          'grid grid-cols-2 gap-2': isGrid,
          ' flex flex-col gap-2': !isGrid,
        })}
      >
        {options.map((option) => (
          <Card
            key={option}
            option={option}
            nextStep={nextStep}
            variant={variant}
            selectedVariants={selectedVariants}
            category={device.category ?? 'phone'}
          />
        ))}
      </div>
      <ConditionModal
        showModal={showHelpMeModal}
        setShowModal={setShowHelpMeModal}
        type={variant.variant === 'screen' ? 'screen' : 'housing'}
        category={device.category ?? 'phone'}
        brand={device.brand ?? 'apple'}
      />
    </>
  );
};

const SelectVariant = ({
  device,
  variants,
  pageType,
  progress,
  devices,
  tradeIn,
}: {
  device: BuybackUSConfig;
  variants: DeviceVariantT[];
  pageType: PageType;
  progress?: {
    step: number;
    setSubStep: Dispatch<SetStateAction<number>>;
    subStep: number;
    steps: number;
  };
  devices: DeviceT[];
  tradeIn?: {
    previousStep: () => void;
  };
}) => {
  const { setQuote, finalQuote, setSummaryQuote } = useBuybackContext();
  const router = useRouter();
  const handle = router.query.handle as string;
  const allVariants: DeviceVariantT[] = [...variants, ...COMMON_VARIANTS];

  const [variant, setVariant] = useState<DeviceVariantT>(allVariants[0]);

  useEffect(() => {
    if (progress) progress.setSubStep(allVariants.indexOf(variant));
  }, [variant]);

  const [selectedVariants, setSelectedVariants] = useState<SelectedVariantsT>(
    {},
  );

  const nextStep = (key: DeviceVariantT, value: string) => {
    const data = { ...selectedVariants, [key.variant]: value };

    setSelectedVariants(data);
    const next = allVariants[allVariants.indexOf(variant) + 1];

    if (next) {
      setVariant(next);
    } else {
      const filteredDevices = devices
        .filter((d) =>
          Object.keys(data).every((v) => {
            if (!['condition', 'screen', 'housing'].includes(v))
              return d[v as VariantsT] === data[v as VariantsT];

            return (
              d.condition ===
              deduceConditionFromDeviceIssues(
                {
                  [streamlineCondition(data.screen ?? '', 'screen')]: true,
                  [streamlineCondition(data.housing ?? '', 'housing')]: true,
                },
                data.condition ?? '',
              )
            );
          }),
        )
        .sort((a, b) => {
          const aBasePriceUsed = a['basePrice-used'] ?? 0;
          const bBasePriceUsed = b['basePrice-used'] ?? 0;

          return bBasePriceUsed - aBasePriceUsed;
        });

      if (filteredDevices.length) {
        setQuote({ ...filteredDevices[0], isAccepted: false });
        setSummaryQuote({
          model: device.title ?? '',
          total: filteredDevices[0]['basePrice-used'],
          image: devices[0].image,
          ...data,
        });
      } else {
        setQuote(null);
        setSummaryQuote({
          model: device.title ?? '',
          total: 0,
          image: devices[0].image,
          ...data,
        });
      }

      if (finalQuote) {
        trackQuoteEvent(
          SegmentEvent.BUYBACK_OFFERS_GENERATED,
          {
            ...finalQuote,
            unlockedStatus: data.carrier ?? '',
            salesChannel: tradeIn
              ? BuybackSalesChannel.TRADE_IN
              : BuybackSalesChannel.BUYBACK,
          },
          () => !tradeIn && router.push(`/buyback/${handle}/valuation`),
        );
      }
    }
  };

  const handleBackClick = () => {
    if (allVariants.indexOf(variant) === 0) return router.back();

    return setVariant(allVariants[allVariants.indexOf(variant) - 1]);
  };

  return (
    <>
      {tradeIn && (
        <button
          type="button"
          onClick={() => {
            if (allVariants.indexOf(variant) === 0) tradeIn.previousStep();
            else setVariant(allVariants[allVariants.indexOf(variant) - 1]);
          }}
          className="flex items-center gap-2 font-bold text-[#B1B2B2]"
        >
          <div className="h-6 w-6">
            <Image
              alt="Back"
              src={arrowRightIcon}
              height={50}
              width={50}
              quality={100}
              className="h-full w-auto rotate-180 object-contain"
            />
          </div>
          <span className="block">Back</span>
        </button>
      )}
      {progress && (
        <ProgressBar
          pageType={pageType}
          back={handleBackClick}
          step={progress.step}
          subStep={progress.subStep}
          steps={progress.steps}
        />
      )}
      <div
        className={cn({
          'mt-8': progress,
          'mt-4': tradeIn,
        })}
      >
        <Variant
          variant={variant}
          device={device}
          nextStep={nextStep}
          selectedVariants={selectedVariants}
        />
      </div>
    </>
  );
};

export default SelectVariant;
